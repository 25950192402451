import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import EvidenceBio from '../components/EvidenceBio'
import EvidenceCard from '../components/EvidenceCard'
import Hero from '../components/Hero'
import CallToAction from '../components/CallToAction'
import clinicalPoster from '../downloads/clinical-poster.pdf'
import { FaArrowRight } from 'react-icons/fa'

export default function evidencePage() {
  return (
    <main className="evidence">
      <Hero
        color="blue"
        image="data-devices"
        heading="The proof behind the system is in the real-world evidence"
        text="Top healthcare companies trust us to help thousands of patients manage their medication schedule day after day."
      />

      <div className="page-section behavioral-change">
        <h2>A proven behavioral change system</h2>
        <p className="final-p">
          Aidia is a proven behavioral change system that integrates effortlessly into a patient’s life and works in
          real-time to focus care team interventions when they can make a difference.
          <br />
          <br />
          Given the high level of investment to bring a medicine to market and then to ensure a patient is able to
          benefit from it, it’s critical that a patient has a positive medication onboarding experience. Aidia has
          consistently proven the ability, across disease states, to help patients start and stay on their medicine.
        </p>
        <div className="page-section-content">
          <StaticImage
            src="../images/retention.png"
            alt="Retention in Specialty Medication Setting"
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>

      <div className="page-section--blue additional-prescriptions">
        <div className="page-section-content">
          <h2>Patients benefit from additional prescriptions</h2>
          <p className="final-p">
            In addition to significantly improving onboarding and retention Aidia has proven to improve patient
            compliance with their dosing schedule resulting in less medication gap days. This combination of increased
            retention and compliance with dosing schedule ensures the patient is able to benefit from additional
            prescriptions.
          </p>
        </div>

        <div className="page-section-content">
          <StaticImage
            src="../images/mean-trx.png"
            alt="Mean TRx in Oncology Setting"
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>

      <div className="page-section improved-retention">
        <h2>Significantly improved retention regardless of payer</h2>
        <p className="final-p">
          Regardless of payer, the AdhereTech cohort 12-month retention significantly outperforms that of the
          non-AdhereTech control group.
        </p>
        <div className="page-section-content">
          <StaticImage
            src="../images/payer-chart.png"
            alt="Retention by Payer"
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>

      <div className="page-section--blue consistently-proven-results">
        <div className="page-section-content results">
          <h3>
            Consistently proven results across different therapies and disease states, published by top pharmacies and
            academic health centers.
          </h3>
        </div>
        <div className="page-section-content evidence-cards">
          <div className="line-one">
            <EvidenceCard
              header="Avella Pharmacy"
              subheader="UnitedHealth Group"
              text="26% increased time on therapy"
              subtext="per patient, per year"
              linkUrl="#avella"
            />
            <EvidenceCard
              header="Journal of Managed Care & Specialty Pharmacy (JMCP)"
              subheader="Avella Specialty Pharmacy, UnitedHealth Group"
              text="4.7 out of 5"
              subtext="overall patient satisfaction"
              linkUrl="#jmcp"
            />
            <EvidenceCard
              header="Journal of Acquired Immune Deficiency Syndromes (JAIDS)"
              subheader="Weill Cornell Clinical Trials Unit"
              text="+1 additional dose,"
              subtext="per week, per levels in the blood"
              linkUrl="#jaids"
            />
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content evidence-bios">
          <div id="avella"></div>
          <EvidenceBio
            header="Avella Pharmacy"
            subheader="UnitedHealth Group"
            description="Analysis of patients using the Aidia System across five specialty medications showed consistent improvements in adherence, duration, refills, and patient experience compared with control groups not using the system:"
            statOne={
              <div className="stat">
                <h3>15% increased adherence</h3>
                <p>at the dose-level</p>
              </div>
            }
            statTwo={
              <div className="stat">
                <h3>9% increase in fill rates</h3>
                <p>per patient, per year</p>
              </div>
            }
            statThree={
              <div className="stat">
                <h3>1-2 additional fills</h3>
                <p>of medication per patient per year</p>
              </div>
            }
            bulletOne={
              <p className="bullet">
                “AdhereTech{' '}
                <span>improves adherence and generates 1-2 additional fills of medication per patient per year</span>,
                across multiple specialty medications and diseases.”
              </p>
            }
            bulletTwo={
              <p className="bullet">
                “Patient feedback is overwhelmingly positive, with the average{' '}
                <span>patient satisfaction rating at 4.7 out of 5.”</span>
              </p>
            }
            bulletThree={
              <p className="bullet">
                “AdhereTech program continues to achieve <span>stellar results for patients</span>. One of the many
                benefits is that the program delivers additional support to patients or caregivers when it’s needed
                most, leading to a great patient experience.”
              </p>
            }
            linkText="View published report"
            linkUrl={
              'https://www.prnewswire.com/news-releases/avellas-use-of-adheretech-improves-adherence-and-generates-one-to-two-additional-fills-per-patient-per-year-across-multiple-specialty-medications-and-diseases-300678286.html'
            }
          />
          <div id="jmcp"></div>
          <EvidenceBio
            header="Journal of Managed Care & Specialty Pharmacy (JMCP)"
            subheader="Avella Specialty Pharmacy, UnitedHealth Group"
            peerReviewed
            description="In a real-world pharmacy setting, the Aidia System with pharmacist interventions was associated with increased medication adherence:"
            statOne={
              <div className="stat">
                <h3>12.6% increased adherence</h3>
                <p>with more consistent dose-time behavior compared with control group</p>
              </div>
            }
            statTwo={
              <div className="stat">
                <h3>1 additional cycle</h3>
                <p>of medication taken compared with control group over 6 months</p>
              </div>
            }
            statThree={
              <div className="stat">
                <h3>5 out of 5</h3>
                <p>(strongly agree) rating for ease of setup and ease of use</p>
              </div>
            }
            bulletOne={
              <p className="bullet">
                “[AdhereTech] demonstrated a <span>significantly higher median adherence of 100%</span> compared with
                the control arm median adherence of 87.4% (P=0.001).”
              </p>
            }
            bulletTwo={
              <p className="bullet">
                “[AdhereTech patients] had <span>more regulated behavior and took a greater proportion of doses</span>{' '}
                during the optimal 2-hour dosing window (83% vs 22%; {`P<0.001`}).”
              </p>
            }
            bulletThree={
              <p className="bullet">
                “In addition to improved adherence, [AdhereTech patients] <span>completed more cycles</span>.”
              </p>
            }
            linkText="View journal article"
            linkUrl="https://www.jmcp.org/doi/full/10.18553/jmcp.2019.25.11.1244"
          />
          <div id="jaids"></div>
          <EvidenceBio
            header="Journal of Acquired Immune Deficiency Syndromes (JAIDS) "
            subheader="Weill Cornell Clinical Trials Unit"
            peerReviewed
            description="Key study shows adherence intervention in patients with HIV, using the Aidia System, resulted in fewer missed doses of medication."
            statOne={
              <div className="stat">
                <h3>1 additional dose per week</h3>
                <p>compared with control group</p>
              </div>
            }
            bulletOne={
              <p className="bullet">
                Patients using AdhereTech{' '}
                <span>
                  had optimal levels of medication in the blood “on the order of around one additional dose per week”
                </span>{' '}
                vs patients not using AdhereTech.
              </p>
            }
            bulletTwo={
              <p className="bullet">
                “Using an objective novel marker, intracellular tenofovir diphosphate by dried blood spot, demonstrates
                the <span>best evidence of the use of [AdhereTech] to improve adherence</span>.”
              </p>
            }
            bulletThree={
              <p className="bullet">
                Conducted and published by Dr. Roy Gulick, Chief of the Division of Infectious Diseases, Former Chairman
                of the FDA Antiviral Drugs Advisory Committee
              </p>
            }
            linkText="View article in JAIDS"
            linkUrl="https://journals.lww.com/jaids/Abstract/2021/01010/Randomized_Pilot_Study_of_an_Advanced_Smart_Pill.11.aspx"
            secondLink={
              <a className="call-to-action-link call-to-action-link__primary" href={clinicalPoster} download>
                View clinical poster
                <FaArrowRight className="arrow-right" size={28} />
              </a>
            }
          />
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            headerText="Partner with us"
            content="Speak with a representative about how to support your organization’s patient populations."
            linkText="Contact Us"
            linkUrl="/contact-us"
            color="blue"
          />
        </div>
      </div>
    </main>
  )
}
