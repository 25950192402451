import React from 'react'

export default function EvidenceCard({ header, subheader, text, subtext, linkUrl }) {
  return (
    <div className="evidence-card">
      <div className="evidence-card__content">
        <div className="header__section">
          <h3>{header}</h3>
          <p>{subheader}</p>
        </div>
        <div className="body__section">
          <h2>{text}</h2>
          <p>{subtext}</p>
        </div>
        <div className="link__section">
          <a href={linkUrl}>Read study detail</a>
        </div>
      </div>
    </div>
  )
}
