import React from 'react'
import CallToActionLink from './CallToActionLink'

export default function EvidenceBio({
  header,
  subheader,
  description,
  statOne,
  statTwo,
  statThree,
  bulletOne,
  bulletTwo,
  bulletThree,
  linkUrl,
  linkText,
  peerReviewed,
  secondLink,
}) {
  return (
    <div className="evidence-bio">
      <div className="header__section">
        <h2>{header}</h2>
        <h3>{subheader}</h3>
        {peerReviewed && <h3>Peer-reviewed</h3>}
        <p>{description}</p>
      </div>
      <div className="body__section">
        <div>
          <div>
            <div className="stats">
              {statOne}
              {statTwo && statTwo}
              {statThree && statThree}
            </div>
            <div className="links links--desktop">
              {secondLink && secondLink}
              <CallToActionLink external color="blue" linkText={linkText} linkUrl={linkUrl} />
            </div>
          </div>
        </div>
        <div className="bullets">
          {bulletOne}
          {bulletTwo}
          {bulletThree}
        </div>
        <div className="links links--mobile">
          {secondLink && secondLink}
          <CallToActionLink external color="blue" linkText={linkText} linkUrl={linkUrl} />
        </div>
      </div>
    </div>
  )
}

EvidenceBio.defaultProps = {
  peerReviewed: false,
  secondLink: false,
}
